.banner {
    padding: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
  }
  
  .first-flex {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .first-column, .second-column {
    flex: 1;
    margin: 10px;
  }
  
  .heading {
    color: black;
    font-family: "Acme, cursive";
    text-align: left;
  }
  
  .description {
    font-family: "Acme, cursive";
    text-align: left;
  }
  
  .cta-button {
    background-color: #f8841e;
    border: none;
    color: white;
    font-family: "Acme, cursive";
    padding: 10px 20px;
    cursor: pointer;
    border-radius: "15px";
  }
  
  .second-flex, .third-flex, .fourth-flex {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

  }
  
  .column {
    flex: 1;
    margin: 10px;
    text-align: center;
  }
  
  .footer {
    text-align: center;
    padding: 20px 0;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
  }
  
  .footer-content {
    color: #666;
  }
  
  .footer-link {
    color: black;
    margin: 0 5px;
    text-decoration: none;
  }
  