.mobile-menu-list {
  width: fit-content;
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-menu-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  text-decoration: none;
  color: #333;
  font-family: "Acme, cursive";
  font-size: 18px;
  transition: background-color 0.3s;
}

.mobile-menu-icon {
  height: 30px;
}